import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const Call = (props) => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      contactJson {
        line {
          name
          url
        }
        facebook {
          name
          url
        }
        tiktok {
          name
          url
        }
        phone1_th
        phone1_en
        phone2_th
        phone2_en
        email
        contact_button_link
      }
    }
  `);
  const { language, t } = useI18next();
  return (
    <div className="call">
      <div className="call-box-top">
        <div className="call-phone">
          <img
            alt="mobile icon"
            className="social-icon"
            src="/images/social/mobile.svg"
          ></img>{" "}
          <a href={"tel:" + data.contactJson[`phone1_${language}`]}>
            {data.contactJson[`phone1_${language}`]}
          </a>
          ,{" "}
          <a href={"tel:" + data.contactJson[`phone2_${language}`]}>
            {data.contactJson[`phone2_${language}`]}
          </a>{" "}
        </div>
        {data.contactJson.facebook && (
          <div className="call-phone">
            <img
              className="social-icon"
              alt="facebook icon"
              src="/images/social/facebook.svg"
            ></img>{" "}
            <a href={data.contactJson.facebook.url}>
              {data.contactJson.facebook.name}
            </a>
          </div>
        )}
        {data.contactJson.line && (
          <div className="call-phone">
            <img
              className="social-icon-line"
              alt="line icon"
              src="/images/social/line.svg"
            ></img>{" "}
            <a href={data.contactJson.line.url}>{data.contactJson.line.name}</a>
          </div>
        )}
        {data.contactJson.tiktok && (
          <div className="call-phone">
            <img
              className="social-icon"
              alt="tiktok icon"
              src="/images/social/tiktok.svg"
            ></img>{" "}
            <a href={data.contactJson.tiktok.url}>
              {data.contactJson.tiktok.name}
            </a>
          </div>
        )}
        {data.contactJson.email && (
          <div className="call-email">
            <img
              className="social-icon"
              alt="email icon"
              src="/images/social/email.svg"
            ></img>{" "}
            <a href={`mailto:${data.contactJson.email}`}>
              {data.contactJson.email}
            </a>
          </div>
        )}
      </div>
      {props.showButton && (
        <div className="call-box-bottom">
          <a href={data.contactJson.contact_button_link} className="button">
            {t("ติดต่อเรา")}
          </a>
        </div>
      )}
    </div>
  );
};

export default Call;
