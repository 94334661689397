import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Call from "../components/Call";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const Contact = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Layout bodyClass="page-default-single">
      <SEO title={t("ติดต่อเรา")} />
      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-8">
            <h1 className="title">{t("ติดต่อเรา")}</h1>
            <Call showButton={false} />
            <div className="content mt-4">
              <p>
                {t("บริษัท เอ็น.อาร์.เอฟ.เพาเดอร์โค้ทติ้ง จำกัด")}
                <br />
                {t("24/9 ม.8 ต.บ้านเกาะ อ.เมือง จ.สมุทรสาคร 74000")}
              </p>
              <p>
                {t("แผนที่")}:{" "}
                <a href="https://goo.gl/maps/EgU36QQdtbTBRr897">Google Map</a>
              </p>
              <table>
                <thead>
                  <tr>
                    <th>{t("วัน")}</th>
                    <th>{t("เวลาทำการ")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{t("อาทิตย์")}</td>
                    <td>{t("ปิด")}</td>
                  </tr>
                  <tr>
                    <td>{t("จันทร์")}</td>
                    <td>{t("8:00 - 17:00น.")}</td>
                  </tr>
                  <tr>
                    <td>{t("อังคาร")}</td>
                    <td>{t("8:00 - 17:00น.")}</td>
                  </tr>
                  <tr>
                    <td>{t("พุธ")}</td>
                    <td>{t("8:00 - 17:00น.")}</td>
                  </tr>
                  <tr>
                    <td>{t("พฤหัสบดี")}</td>
                    <td>{t("8:00 - 17:00น.")}</td>
                  </tr>
                  <tr>
                    <td>{t("ศุกร์")}</td>
                    <td>{t("8:00 - 17:00น.")}</td>
                  </tr>
                  <tr>
                    <td>{t("เสาร์")}</td>
                    <td>{t("8:00 - 17:00น.")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248038.44995921897!2d100.14414562486087!3d13.742603182528924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2c7b0d82f0169%3A0xaab91308d7c3e6d0!2sN.R.F.Powdercoating!5e0!3m2!1sth!2sth!4v1666066475627!5m2!1sth!2sth"
              width="100%"
              height="300px"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Contact;
